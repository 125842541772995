
















import { gsap } from 'gsap'
import { defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'accordion',
  components: {},
  props: {
    title: {
      required: true,
      type: String,
    },
    identifiant: {
      type: String,
      required: true,
    },
    initState: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isOpen = ref(false)
    const el = ref<HTMLElement>()

    isOpen.value = props.initState

    const toggle = () => {
      if (isOpen.value) {
        isOpen.value = false
        gsap.to(`#${props.identifiant}`, {
          duration: 0.25,
          height: 0,
          ease: 'power1.out',
        })
      } else {
        isOpen.value = true
        gsap.set(`#${props.identifiant}`, { height: 'auto' })
        gsap.from(`#${props.identifiant}`, {
          duration: 0.25,
          height: 0,
          ease: 'power2.in',
        })
      }
    }

    onMounted(() => {
      if (el.value?.classList.contains('is-open')) {
        isOpen.value = true
      }
    })

    return {
      el,
      isOpen,
      toggle,
    }
  },
})
