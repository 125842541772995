

























































import dayjs from 'dayjs'
import { useState, useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

import Accordion from '@/components/ui/Accordion.vue'
import EanInfo from '@/components/my/EanInfo.vue'
import {
  cadran,
  convertUnit,
  convertRegister,
  trimzerosIndex,
} from '@/inc/utils'

export default defineComponent({
  name: 'meter-history',
  components: { Accordion, EanInfo },
  props: {
    indexData: {
      type: Object,
      required: true,
    },
  },

  setup(_, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const { getEan } = useGetters('user', ['getEan'])

    const ean = getEan.value(ctx.root.$route.params.id)

    return {
      dayjs,
      convertRegister,
      convertUnit,
      trimzerosIndex,
      cadran,
      ean,
      i18n,
    }
  },
})
